import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Anthony 👋`}</h1>
    <p>{`💻 I'm a full-stack software engineer, ☕ coffee & espresso enthusiast, 🍩 doughnut & bread lover.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      