import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Blockchain Gaming" link="https://www.bcg.gg/" bg="linear-gradient(to right, #090F13 0%, #2B354C 100%)" mdxType="ProjectCard">
  Co-founder for web3 gaming infrastructure startup
    </ProjectCard>
    <ProjectCard title="Boston React Native" link="https://www.meetup.com/Boston-React-Native/" bg="linear-gradient(to right, #282C34 0%, #61DAFB 100%)" mdxType="ProjectCard">
  Co-organizer for Boston's React Native Meetup (2016-Present)
    </ProjectCard>
    <ProjectCard title="Hand & Terry" link="https://www.kickstarter.com/projects/yale/hand-and-terry-a-better-dress-sock/description" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Co-founded men's dress sock company that was launched on Kickstarter and then
  on Shopify (2014-2019)
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      