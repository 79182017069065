import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "mailto:hello@anthonyliang.com"
      }}>{`Hi`}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/anthonyliang/"
      }}>{`LinkedIn`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/liangtfm/"
      }}>{`Instagram`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/liangtfm"
      }}>{`GitHub`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/liangtfm"
      }}>{`Twitter`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      